import { trackInitiateCheckoutEvents } from "./src/utils/track-pixel-events";

export { wrapRootElement } from "./wrapRootElement";

import ReactDOM from 'react-dom';
import "./src/styles/global.scss"

export function replaceHydrateFunction() {
    return (element:any, container:any, callback:any) => {
        ReactDOM.render(element, container, callback);
    };
}

export const onRouteUpdate = () => {
    // Initialize tracking events
    trackInitiateCheckoutEvents();
}