export const INITIATE_CHECKOUT_CLASS = 'track-initiate-checkout';

/**
 * Initialize Meta Pixel tracking events for InitiateCheckout
 */
export function trackInitiateCheckoutEvents() {
	try {
		if (typeof window !== 'undefined') {

			// Tracking InitiateCheckout event
			// search for a button with the class 'track-click' and add an event listener
			const buttons = document.querySelectorAll(`.${INITIATE_CHECKOUT_CLASS}`);
			buttons.forEach(button => {
				button.addEventListener('click', () => {
					// send a tracking event to Meta Pixel
					// @ts-ignore
					window.fbq('track', 'InitiateCheckout');
				});
			});
		}
	} catch (e) {
		console.error('Error tracking Pixel events', e);
	}
}


/**
 * Track a Lead event after a user submits a form to register Company/IE/Partner
 */
export function trackLeadEvent() {
	try {
		if (typeof window !== 'undefined') {
			// send a tracking event to Meta Pixel
			// @ts-ignore
			window.fbq('track', 'Lead');
		}
	} catch (e) {
		console.error('Error tracking Lead event', e);
	}
}

/**
 * Initialize Meta Pixel tracking events for Contact
 */
export function trackContactClick() {
	try {
		if (typeof window !== 'undefined') {
			// send a tracking event to Meta Pixel
			// @ts-ignore
			window.fbq('track', 'Contact');
		}
	} catch (e) {
		console.error('Error tracking Contact event', e);
	}
}

export function trackInitiateCheckoutClick() {
	try {
		if (typeof window !== 'undefined') {
			// send a tracking event to Meta Pixel
			// @ts-ignore
			window.fbq('track', 'InitiateCheckout');
		}
	} catch (e) {
		console.error('Error tracking InitiateCheckout event', e);
	}
}

export function isInitiateCheckoutLink(link: string): boolean {
	const formLinks = ['sign-up-for-IE', 'sign-up-for-partners', 'sign-up-for-companies'];
	return formLinks.some(formLink => link.includes(formLink));
}